import { useState, useEffect } from "react";
import {   useWeb3ModalProvider,
  useWeb3ModalAccount,
createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { parseUnits,formatEther,BrowserProvider, Contract,JsonRpcProvider, formatUnits, parseEther } from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import $ from 'jquery';
import DataTable , { createTheme } from 'react-data-table-component';
import {  Link } from 'react-router-dom';
import {
    tokenabi,
    tokenaddress,
      wbtc,
      weth,
      busd,
      usdt,
      usdc,
      erc20abi,
    contract_abi,
    contract_address,
    usdt_abi,
    usdt_address,
    RBC_token,
    RBC_abi,
    projectId
} from "./constant";

import { Sidebar } from './Sidebar'


createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'light');

export function Payout() {
    
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [ selectedCurrencyUserBalance , setselectedCurrencyUserBalance] = useState();
    const [ userUkcBalance , setuserUkcBalance] = useState(0);
    const [ selectedCurrency , setSelectedCurrency] = useState();
    const [ tokenInput , settokenInput ] = useState();
    const [ ukcInput , setukcInput ] = useState();
    const [userId, setUserId] = useState("");
    const [connectedWalletAddr,setConnectedWalletAddr] = useState();
    const [userPackages,setUserPackages] = useState();
    const [packages,setPackages] = useState();
    const [contractObj,setContractObj] = useState();
    const [usdtContractObj,setUsdtContractObj] = useState();
    const [rbcContractObj,setRbcContractObj] = useState();
    const [tableData,setTableData] = useState([])
    const [availableBalance,setAvailableBalance] = useState('Loading..')
    const [availableBalanceReal,setAvailableBalanceReal] = useState('Loading..')
    const [usdtAmount,setUsdtAmount] = useState('Loading..')
    const [rbcAmount,setRbcAmount] = useState('Loading..')
    const [btnDisabled,setBtnDisabled] = useState(true)
   

  
    // 2. Set chains
    const mainnet ={
      chainId: 137,
      chainIdHex:'0x89',
      name: 'Polygon Smart Chain',
      currency: 'MATIC',
      explorerUrl: 'https://bscscan.com',
      rpcUrl: 'https://polygon-rpc.com'
      /*
      chainId: 97,
      name: 'Binance Smart Chain Testnet',
      currency: 'tBNB',
      explorerUrl: 'https://testnet.bscscan.com',
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'*/
    }
  
    const bscRpc = "https://polygon-rpc.com/";
    const validChainId = 137;
    // 3. Create modal
    const metadata = {
      name: 'uckccoin',
      description: 'uckccoin',
      url: 'https://test.ukccoin.io/', // origin must match your domain & subdomain
      icons: ['https://test.ukccoin.io/html/images/UKC_Logo.svg']
    }
  
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })
  
  
  const getSignerOrProvider = async (needSigner = false) => {
    let ethersProvider = new BrowserProvider(walletProvider);
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }
  
      
      const signer = await ethersProvider.getSigner();
  
      if (needSigner) {
        const signer = await ethersProvider.getSigner();
  
        return signer;
      }
  
      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  
  


  const getUserData = async (walletAddr,contractObjGet,rbcContractObjGet) => {
    try {
      if(walletAddr){
        var cuUserId = await contractObjGet.userId(walletAddr);
        cuUserId = parseInt(cuUserId);
        setUserId(cuUserId);

        var user= await contractObjGet.users(cuUserId);
        console.log('user',user);
        var nonWorkingIncome=parseInt(user.nonWorkingIncome);
        var containerIncome=parseInt(user.nonWorkingContainerIncome);
        setAvailableBalanceReal(nonWorkingIncome+containerIncome);
        let drain_income = (nonWorkingIncome+containerIncome)/10**6;
        drain_income = Number(drain_income).toFixed(4);
        setAvailableBalance(drain_income); 
        setUsdtAmount(Number((drain_income)*85/100).toFixed(4))
        setRbcAmount(Number((drain_income)*15/100).toFixed(4))
     
       

      }
      

    } catch (err) {
      console.error(err);
    }
  };


  const accountsChanged =() =>{
    window.location.reload();
  }

  const chainChanged =() =>{
    window.location.reload();

  }

  const isRegistered = async()=>{
    //if(!contractObj){
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
       var contractObjData = new Contract(contract_address,contract_abi, provider);
       setContractObj(contractObjData);
      var usdtContractObjData = new Contract(usdt_address,usdt_abi, provider);
      setUsdtContractObj(usdtContractObjData);
      var rbcContractObjData = new Contract(RBC_token,RBC_abi, provider);
      setRbcContractObj(rbcContractObjData);

      setBtnDisabled(false);
      
      setConnectedWalletAddr(signer.address);
      const checkIt = await contractObjData.isRegistered(signer.address);

      if(checkIt==true){
          getUserData(signer.address,contractObjData,rbcContractObjData);
        
      }
      else {
          window.location.href="/";
      }
    //}
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };



  const withdraw = async() =>{
    try {
      setBtnDisabled(true);
      var minWithdrawal= parseInt(await contractObj.minLimit());
      var minWithdrawalMsg  = minWithdrawal/(10**6);

      if(availableBalanceReal<minWithdrawal){
        return toast.error("Minimum "+ minWithdrawalMsg +"$ is required.");
      }
      


      await contractObj.userWithdraw();
      toast.success('Transaction Successful.');
      setBtnDisabled(false);
      //setTimeout(() => window.location.reload(), 1000);
    }
    catch(e){
      setBtnDisabled(false);
      toast.error(e);
    }
  }
useEffect( () => {

  if (isConnected) {
   
    walletProvider.on('accountsChanged', accountsChanged); 
    walletProvider.on('chainChanged', chainChanged);
    isRegistered();

}
    
    if(!isConnected){
      console.log("b");
        setSelectedCurrency("");
        setukcInput("");
        settokenInput("");
        setuserUkcBalance("");
        setselectedCurrencyUserBalance("");
        $("#currency_id").val("");
        $("#table_body").html();
       // window.location.href="/";
    }
},[isConnected]);


  return (
  <div>
    <div className="top_bar">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText">
            <span className="icon-bar"><i className="fa fa-bars"></i></span>
        </button>
        <div className="row align-items-center">
            <div className="col-4">
            <Link className="navbar-brand" href="/dashboard"><img src="rabito/img/logo.svg" alt="header-Logo" className="logo2" /></Link>
            </div>
            <div className="col-auto ml-auto">
                <w3m-button balance="hide" label="Connect Wallet" />
            </div>
        </div>
    </div>
    <div className="dash_bar_man">
        <div className="left_bar">
            <Sidebar/>
        </div>
        <div className="right_bar">
          <h4 className="fw600 mb-3">Cashout</h4>
          <div className="row justify-content-center" >
            <div className="col-md-6 mb-3">

           { (isConnected) ? 
              <div className="dash_box">
                <h4 className="fw600 mb-md-3 mb-1 "> Available Balance   (<span id="drain_income" style={{color:'#00d7eb'}}>{availableBalance}</span> $)</h4>
                <div className="d-flex justify-content-between">
                  <span className="trophy4 mb-3">
                  <img alt="" src="rabito/img/logo.svg" />
                  </span>
                    <div style={{alignSelf:'center'}}>
                      <button className="btn btn-action  mx-2  mb-1 " data-toggle="modal" data-target="#Withdrawal">Cashout</button>
                    </div>
                </div>
              </div>
              :
              <div></div>
           }
            </div>
          </div>


                <div className="modal fade" id="Withdrawal">
                <div className="modal-dialog">
                  <div className="modal-content">
              
                      
                  
                    <div className="modal-body">
                  
                      <img src="rabito/img/withdrawal_bg.png" className="w100" />
                      <div className="withdrawal_in">
                        <h3 className="mb-2 mb-md-4">Available Balance</h3>
                        <div className="form-group position-relative ">
                          <input type="text" className="input form-control" value={availableBalance} placeholder="Loading.." id="withdrawal_amt" readOnly />
                          <img src="/rabito/img/usdt.png" className="usdt_icon" />
                        </div>

                        <div className="row mt-3 mb-md-4 mb-1">
                          <div className="col-6">
                            <h5> <span id='usdt_amt'>{usdtAmount}</span> <br />USDT</h5>
                          </div>
                          <div className="col-6">
                            <h5><span id='rbc_amt'>{rbcAmount}</span> <br />USDT/RBC</h5>
                        </div>
                          </div>

                              <button type="button" className="btn btn-action  pl-md-5 pr-md-5" onClick={withdraw} id="d_withdrawal" disabled={btnDisabled}
                              >Cashout</button>
                      
                      </div>
                    
                    </div>
              
                    
                  
              
                  </div>
                </div>
              </div>


              
        </div>
    </div>
  </div>

  );
}
