import { useState, useEffect } from "react";
import {   useWeb3ModalProvider,
  useWeb3ModalAccount,
createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { parseUnits,formatEther,BrowserProvider, Contract,JsonRpcProvider, formatUnits, parseEther } from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import {  Link, useParams, useNavigate } from 'react-router-dom';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

import {
contract_abi,
contract_address
} from "./constant";

 
export function Login(props) {
  const navigate = useNavigate();
  let { refWalletAddrParam } = useParams();
  const projectId = '48cc6e7eee3824a768e808c6b51a1491';
  const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
  
  const [refWalletAddr , setRefWalletAddr] = useState();
  const [loginTabClass , setLoginTabClass] = useState();
  const [registerTabClass , setRegisterTabClass] = useState();

  let ethersProvider = "";
  let contractObj;
  // 2. Set chains
  const mainnet ={
    chainId: 137,
    chainIdHex:'0x89',
    name: 'Polygon Smart Chain',
    currency: 'MATIC',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://polygon-rpc.com'
    /*
    chainId: 97,
    name: 'Binance Smart Chain Testnet',
    currency: 'tBNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'*/
  }

  const bscRpc = "https://polygon-rpc.com/";
  const validChainId = 137;
  // 3. Create modal
  const metadata = {
    name: 'uckccoin',
    description: 'uckccoin',
    url: 'https://test.ukccoin.io/', // origin must match your domain & subdomain
    icons: ['https://test.ukccoin.io/html/images/UKC_Logo.svg']
  }

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


const getSignerOrProvider = async (needSigner = false) => {
  try {
    if (!isConnected) {
      throw Error("User disconnected");
    }

    
    const signer = await ethersProvider.getSigner();

    if (needSigner) {
      const signer = await ethersProvider.getSigner();

      return signer;
    }

    return signer;
  } catch (error) {
    console.error("Error in getWeb3Provider:", error);
    throw error; // Propagate the error to the caller or handle as needed
  }
};


const isRegistered = async()=>{
  
  const signer = await getSignerOrProvider(true);
  const provider = await getSignerOrProvider();
   contractObj = new Contract(contract_address,contract_abi, provider);
 
  return  await contractObj.isRegistered(signer.address);
  // if(checkIt==true){
  //   window.location.href="/dashboard";
  // }
}

  const showHideMenu = async () =>{
   
      $(".UKC-menu").toggleClass("show");
   
  }

  const accountsChanged =() =>{
    window.location.reload();
  }

  const chainChanged =() =>{
    window.location.reload();

  }

  const addChain =() =>{
    window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
          chainId: "0x89",
          rpcUrls: ["https://rpc-mainnet.matic.network/"],
          chainName: "Matic Mainnet",
          nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18
          },
          blockExplorerUrls: ["https://polygonscan.com/"]
      }]
  });
  }

  const switchChain = async ()=>{
    await window.ethereum.request({
      "method": "wallet_switchEthereumChain",
      "params": [
      {
        chainId: "0x89"
      }
    ],
    });
  }
  useEffect( () => {
    // console.log('props',aal);
    // var url = window.location.href;
    // var expUrl = url.split("?");
    var refAddr = (refWalletAddrParam) ?? undefined;
    if(refAddr){
      setRefWalletAddr(refAddr);
      setLoginTabClass("")
      setRegisterTabClass("active");
    }
    else {
      setLoginTabClass("active")
      setRegisterTabClass("");
    }
   
    if (isConnected ) {

      ethersProvider = new BrowserProvider(walletProvider);

      walletProvider.on('accountsChanged', accountsChanged); 
      walletProvider.on('chainChanged', chainChanged);
      

    }
    if(!isConnected){
     // console.log("aa");
    }
  },[isConnected]);


  const registerNew = async() =>{
    try{
      if(await isRegistered()){
        toast.error('You are already registered');
      }
      else {
        await contractObj.register(refWalletAddr);
      }
     
    }
    catch (error){
      console.error("Error in getWeb3Provider:", error.message);
    //throw error; // Propagate the error to the caller or handle as needed
    }

   
  }

  const LoginNow = async() =>{
    try{
      
      if(await isRegistered()){
       navigate("/dashboard");
    
      }
      else {
        toast.error('You are not registered');
      }
    }
    catch (error){
      console.error("Error in getWeb3Provider:", error.message);
    //throw error; // Propagate the error to the caller or handle as needed
    }

   
  }




  const updateRefWalletAddr = async(e) => {
   
    setRefWalletAddr( e.target.value);
   
  }

  return (<div>
  
     



  <video className="video-wrap" playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
						<source src="rabito/video.mp4" type="video/mp4" />
</video>

  
      <div className="login_box" >
            <div className="container m-auto">
                      <div className="row ">
                           <div className="col-md-5 m-auto">
                              <div className="blar_box text-center si login_box_in">
                               <Link href="/"> <img src="/rabito/img/logo.svg" alt="header-Logo" className="logo mb-4" /></Link>
                               

                                 <ul className="nav tab_login mb-4">
                                    <li className="nav-item">
                                      <a className={'nav-link '+loginTabClass } data-toggle="tab" href="#Login">Login</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className={'nav-link '+registerTabClass } data-toggle="tab" href="#register">Register</a>
                                    </li>
                                     
                                  </ul>
                                  
                             
                                  <div className="tab-content">
                                    <div className={'tab-pane container '+loginTabClass} id="Login">
                                       
                                    { (isConnected===false) ?  <w3m-button balance="hide" label="Connect Wallet"/> :
                                       <a href="javascript:void(0)" onClick={LoginNow} className="btn btn-action w100"><span>Login</span> </a> }
                                 
                                    </div>
                                    <div className={'tab-pane container '+registerTabClass} id="register"> 
                                     
                                       <div className="mb-4"> 
                                       <label className="text-left w100">Sponser ID</label>
                                       <input value={refWalletAddr} onChange={updateRefWalletAddr} type="text" className="form-control  " placeholder="Refferal Code" />
                                       </div>
                                        { (isConnected===false) ? <w3m-button balance="hide" label="Connect Wallet"/> : <a href="javascript:void(0)" onClick={registerNew} className="btn btn-action w100"><span>Register</span> </a> }
                                    </div>
                                  </div>

                             
                        </div>
                      </div>
                </div>
            </div>

    </div>

  </div>
  
  );
}
