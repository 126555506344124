import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import Routes from react-router-dom
import { Home } from './Home';
import { Ido } from './Ido';
import { Login } from './Login';
import { Dashboard } from './Dashboard';
import { UserPackages } from './UserPackages';
import { DirectIncome } from './DirectIncome';
import { MissedDirectIncome } from './MissedDirectIncome';
import { ContainerIncome } from './ContainerIncome';
import { MissedContainerIncome } from './MissedContainerIncome';
import { DrainIncome } from './DrainIncome';
import { MissedDrainIncome } from './MissedDrainIncome';
import { ContainerTree } from './ContainerTree'
import { Payout } from './Payout';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes> {/* Wrap Routes around Route components */}
          <Route path='/' element={<Home />} /> {/* Specify element prop instead of component prop */}
          <Route path='/login' element={<Login />} /> {/* Specify element prop instead of component prop */}
          <Route path='/dashboard' element={<Dashboard />} /> {/* Specify element prop instead of component prop */}
          <Route path='/userpackages' element={<UserPackages />} /> {/* Specify element prop instead of component prop */}
          <Route path='/direct-income' element={<DirectIncome />} /> {/* Specify element prop instead of component prop */}
          <Route path='/missed-direct-income' element={<MissedDirectIncome />} /> {/* Specify element prop instead of component prop */}
          <Route path='/spot-income' element={<ContainerIncome />} /> {/* Specify element prop instead of component prop */}
          <Route path='/missed-spot-income' element={<MissedContainerIncome />} /> {/* Specify element prop instead of component prop */}
          <Route path='/drain-income' element={<DrainIncome />} /> {/* Specify element prop instead of component prop */}
          <Route path='/missed-drain-income' element={<MissedDrainIncome />} /> {/* Specify element prop instead of component prop */}
          <Route path='/spots-tree' element={<ContainerTree />} /> {/* Specify element prop instead of component prop */}
          <Route path='/payouts' element={<Payout />} /> {/* Specify element prop instead of component prop */}

         
          <Route path='/register/:refWalletAddrParam' element={<Login />} /> {/* Specify element prop instead of component prop */}
          
        </Routes>
      </Router>
	 
        <ToastContainer />
    </div>
	
  );
}

export default App;

