import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {   useWeb3ModalProvider,
    useWeb3ModalAccount,
  createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

export function Sidebar() {
    const [activeMenu,setActiveMenu] = useState();
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    useEffect( () => {

        var url = window.location.href;
        var expUrl = url.split("/");
        console.log('expUrl',expUrl);
        
        setActiveMenu(expUrl[3])

      },[]);

    return (
        <nav className="navbar2 ">
        <div className="">
           
           
            <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav m-auto ">
                    <li className="nav-item">
                        <Link className={activeMenu == "dashboard" ? "active nav1" : "nav1"} to="/dashboard"><i className="fa fa-home mr-2"></i> Dashboard</Link>
                    </li>
                     
                {(isConnected) ? 
                   <li class="nav-item">
                       <Link className={activeMenu == "userpackages" ? "active nav1" : "nav1"} to="/userpackages"><i className="fa fa-file-text-o mr-2"></i> User packages</Link>
                   </li>
                   :
                   ""
                    }
                   <li className="nav-item">
                       <Link className={activeMenu == "direct-income" ? "active nav1" : "nav1"} to="/direct-income"><i className="fa fa-share-square-o mr-2"></i> Direct Income</Link>
                   </li>

                   <li class="nav-item">
                       <Link className={activeMenu == "missed-direct-income" ? "active nav1" : "nav1"} to="/missed-direct-income"><i className="fa fa-minus-square-o mr-2"></i> Missed Direct Income</Link>
                   </li>

                   <li className="nav-item">
                       <Link className={activeMenu == "spot-income" ? "active nav1" : "nav1"} to="/spot-income"><i className="fa fa-gg-circle mr-2"></i> Container Income </Link>
                   </li>

                   <li className="nav-item">
                       <Link className={activeMenu == "missed-spot-income" ? "active nav1" : "nav1"} to="/missed-spot-income"><i className="fa fa-minus-circle mr-2"></i> Missed Container </Link>
                   </li>

                   <li className="nav-item">
                       <Link className={activeMenu == "drain-income" ? "active nav1" : "nav1"} to="/drain-income"><i className="fa fa-superpowers mr-2"></i> Drain Income </Link>
                   </li>
                   <li className="nav-item">
                       <Link className={activeMenu == "missed-drain-income" ? "active nav1" : "nav1"} to="/missed-drain-income"><i className="fa fa-calendar-minus-o mr-2"></i> Missed Drain Income </Link>
                   </li>

                   <li className="nav-item">
                       <Link className={activeMenu == "spots-tree" ? "active nav1" : "nav1"} to="/spots-tree"><i className="fa fa-sitemap mr-2"></i> Container Tree </Link>
                   </li>
                   {(isConnected) ? 
                   <li className="nav-item">
                       <Link className={activeMenu == "payouts" ? "active nav1" : "nav1"} to="/payouts"><i className="fa fa-money mr-2"></i> Cashout </Link>
                   </li>
                   : "" }
                  
                   
                </ul>
                <div className="vertical-social  mt-4" data-wow-delay="0.1s">
           <ul>
             
               <li><a target="_blank" href="https://t.me/Rabito_official_group"><i className="fa fa-telegram"></i></a></li>
               
           </ul>
       </div>
            </div>
            

        
        </div>
    </nav>
    );
 
}
