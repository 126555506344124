import React,{ useState, useEffect } from 'react';
import {  Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import Web3 from 'web3'

import {
  contract_abi,
  contract_address,
  chainnetwork
} from "./constant";
 
export function Home() {
  const navigate = useNavigate();
  var web3 = new Web3(chainnetwork);
  const showHideMenu = async () =>{
   
      $(".UKC-menu").toggleClass("show");
   
  }

  const txList = async() =>{
 
       
       let contract='0x02a660dbcB004298Cbd180feBcBc279d97C53100';
       let apikey='W5CPVKVP3Y1I4IZE7E6B8Q1Y75TJKBICHR';
      var data;
      await fetch(`
      https://api.polygonscan.com/api?module=account&action=txlist&address=${contract}&startblock=0&endblock=latest&page=1&offset=5&sort=desc&apikey=${apikey}`).then((d)=>d.json()).then((d)=>data=d);
      
      if(data?.status && data?.status=='1')
      {
        let html='';
          let res=data?.result;
        for(let i=0; i<res.length;i++)
        {
          html+=`
          <p class="pt-3 pl-3 pr-3 d-flex  align-items-center"><i class="fa fa-user mr-1" style="color: #00b1d4;"></i>
                                      &nbsp;Block No - <span class="fw-bold mr-1 " style="color: #00C7EF;">${res[i]['blockNumber']}</span> ${res[i]['hash'].substr(0,5)+'.....'+res[i]['hash'].substr(-5)} <span class="float-end">${res[i]['functionName'].split('(')[0]} &nbsp; <a href="https://polygonscan.com/tx/${res[i]['hash']}" target="_blank"><i class="fa fa-share-square-o" style="color: #00C7EF;"></i></a></span></p><hr class="mr-3 ml-3">
          `
        }
  
        html+=`<p class="pt-3 pl-3 pr-3 align-items-center text-center "> <a class='btn btn-primary btn-lg' href="https://polygonscan.com/address/${contract}" target="_blank">See More</a> </p>`
  
        $('#transactions').append(html);
  
      }
  
  }
  const CheckDemo = () =>{
    sessionStorage.setItem('preview_adr',"0x0876307652F29E967369F16FF9f9Fba9436ba7f5");
    navigate('/dashboard');
  }

  const PreviewUser = async() =>{
   
    let val= $('#prev-input').val().trim();

    console.log(val);

    if(isNaN(val) )
        return toast.error('ID is invalid.');
      else if( Number(val) < 10)
          val=10;

          

    let wallet_address=null;
  if(!val) return toast.error('Enter user ID or wallet address.','Preview');

  $('#prev-input').val('Loading...');
  $('#prev-btn').attr('disabled',true);
  $('#prev-input').attr('disabled',true);

  let Contract = new web3.eth.Contract(contract_abi,contract_address);

  if(isNaN(val+val))
  {
    if(!web3.utils.isAddress(val)){
      $('#prev-input').val('');
      $('#prev-input').attr('disabled',false);
      $('#prev-btn').attr('disabled',false);
       return toast.error('Invalid wallet address.','Preview'); }

    if(!await Contract.methods.isRegistered(val).call()){
      $('#prev-input').val('');
      $('#prev-input').attr('disabled',false);
      $('#prev-btn').attr('disabled',false);
       return toast.error('Invalid wallet address.','Preview');}

       wallet_address=val;
  }
  else
  {
     wallet_address= await Contract.methods.userIdtoAddress(val).call();
    if(wallet_address=='0x0000000000000000000000000000000000000000'){
      $('#prev-input').val('');
      $('#prev-input').attr('disabled',false);
      $('#prev-btn').attr('disabled',false);
       return toast.error('Invalid user ID.','Preview');}
  }
  $('#prev-input').val('Preview');
  sessionStorage.setItem('preview_adr',wallet_address);
  toast.success('Previewing...','Preview');
  navigate('/dashboard');

  }


useEffect( () => {

  txList()
},[]);
  return (
    <>
    <video class="video-wrap" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
    <source src="rabito/video.mp4" type="video/mp4" />
  </video>
    <nav class="navbar navbar-expand-lg">
    <div class="container">
     
      <Link class="navbar-brand" href="/">
      <img src="rabito/img/logo.svg" alt="header-Logo" class="logo" /></Link>
     
      <div class="btns">
        <Link class="btn btn-action ml-auto" style={{backgroundColor:"#52565aae"}} to="/login">Connect Wallet</Link>
        <a class="btn btn-action ml-3" target="_blank" style={{backgroundColor:"#52565aae"}} href="https://rbctoken.info">Buy RBC Now</a>
      </div>
    </div>

    </nav>

  <div className="mt-5 pt-5">
  <div className="container mt-1  ">
            <div className="row ">
                <div className="col-sm-10 col-md-10 mx-auto p-md-0">
                    <div className="man_top_box">
                      <div className="row">
                          <div className="col-sm-8  col-md-8    ">
                              <h1 className="fw-bold">Welcome to Rabito™</h1>
                              <p>Connect your wallet to start working. First time here? Watch a tutorial to
                                  learn <a href="https://t.me/Rabito_official_group" target="_blank" className="text-white fw-bold">more</a></p>
                              <p className="btnconnect"><a href="https://t.me/Rabito_official_group" target="_blank"> <button className=" btn  text-white" style={{backgroundColor:"#000000ae"}}>Tutorial</button></a>
                              </p>
                          </div>
                          <div className="col-sm-4  mx-auto  col-md-4">
                                  <img src="rabito/img/wlcome_sec_one.png" alt="token" className="RbcLogo1 img-fluid" />
                                <div className="imgbox">
                                  <img src="rabito/img/welcome_sec-two.png" alt="token" className="RbcLogo2 mx-auto" />
                                </div>
                          </div>
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
           
                      <h3 className="fw-bold mb-1 ">Account Preview</h3>
                                  <p className="h6 ">Look up any Rabito member account in preview mode. </p>
                          <div className="row">
                          <div className="col-sm-7 col-md-7 mt-1  ">
                          <div className="p-4 choose-item">
                                  
                                  <p className=" h6 mb-3">Enter ID or Polygon Wallet </p>
                                  <div className="d-flex mt-sm-0 mt-4">
                                  <input type="text" className="form-control  input_banner" placeholder="example:1000" id="prev-input" />
                                  <button className="btn btn-action ml-2 prev-btn" id="prev-btn" onClick={PreviewUser}>Preview</button>
                                  </div> 
                                  
                              </div>
                              </div>
                              
                              <div className="col-sm-1 col-md-1"></div>
                              <div className="col-sm-4 col-md-4 mt-1">
                              <div className="p-4 choose-item">
                                  <p className="fw-bold mb-4 h6 ">Don’t know any ID? <img src="rabito/img/demo.png" className="right_ab" /> </p>
                                  
                                      <button className="btn text-white" onClick={CheckDemo} id="check-demo" style={{backgroundColor:"#00C7EF"}}>Check Demo
                                      </button>
                                      
                                  
                      
                              </div>
                              </div>
                          </div>
                      
              </div>
        

        <div className="mt-5">
           <h3 className="fw-bold mb-4 refoh3">Platform Recent Activity <i className="fa fa-question-circle"></i></h3>
          
           <div className="row">
           <div className=" col-sm-8 col-md-8 pb-3">
           <div className="box" id="transactions">
                             
        </div>
       </div>
      
       <div className="col-sm-4 col-md-4 p_lh_i">

                            <div className="row ">
                                <div className="col-sm-12 p-3 col-md-12 choose-item">
                                    <p>Presentation 
                                    </p>
                                    <a className="btn" href="https://t.me/Rabito_official_group" target="_blank">Download</a>
                                </div>
                                <div className="col-sm-12 col-md-12 mt-3 p-3 choose-item">
                                    <p>Project Video
                                    </p>
                                    <a className="btn" href="https://m.youtube.com/@RBCRabito" target="_blank">Watch</a>
                                </div>
                                <div className="col-sm-12 mt-2 col-md-12 p-3 pb-0 choose-item">
                                    <p>RBC Tokenomics </p>
                                    <hr />
                                    <a className="btn" href="https://t.me/rbc_token" target="_blank">View Whitepaper</a>

                                </div>
                            </div>
                        </div>
       </div>




       <div className=" mx-auto text-center mt-5 pt-md-3 pb-3  pb-md-5">
                <p className="fs-2 fw-bold">Need help with using the platform?</p>
                <p>Get qualified support from Rabito experts via online chat</p>
                <p>
                    <a href="https://t.me/Rabito_official_group" target="_blank">
                        <button className="btn text-white fw-bold " style={{backgroundColor:"#00C7EF"}}>Contact Support
                        </button></a>
                </p>
            </div>



    </div>
   </div>
    </div>
    </div>
    </div>
    <div id="scroll-to-top" style={{display:'block'}}><i className="fa fa-arrow-up fa-fw"></i></div>
    <div className="banner-social-wrap">
      <ul>
        <li><a href="https://t.me/Rabito_official_group" target="_blank" className="banner-social-link"><i className="fa fa-telegram" aria-hidden="true"></i></a></li>

        <li><a href="https://t.me/Rabito_official_group" target="_blank" className="banner-social-link"><i className="fa fa-facebook-official" aria-hidden="true"></i></a></li>
        <li><a href="https://t.me/Rabito_official_group" target="_blank" className="banner-social-link"><i className="fa fa-times" aria-hidden="true"></i></a></li>
        <li><a href="https://t.me/Rabito_official_group" target="_blank" className="banner-social-link">
          <i className="fa fa-instagram" aria-hidden="true"></i></a></li>
      
      </ul>
    </div>
    </>
  );
}
