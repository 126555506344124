import { useState, useEffect } from "react";
import {   useWeb3ModalProvider,
  useWeb3ModalAccount,
createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { parseUnits,formatEther,BrowserProvider, Contract,JsonRpcProvider, formatUnits, parseEther } from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import $ from 'jquery';
import DataTable , { createTheme } from 'react-data-table-component';
import {  Link } from 'react-router-dom';
import {
    tokenabi,
    tokenaddress,
      wbtc,
      weth,
      busd,
      usdt,
      usdc,
      erc20abi,
    contract_abi,
    contract_address,
    usdt_abi,
    usdt_address,
    RBC_token,
    RBC_abi,
    projectId,
    chainnetwork
} from "./constant";

import { Sidebar } from './Sidebar'
import Web3 from 'web3'

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'light');

export function DrainIncome() {
    var web3 = new Web3(chainnetwork);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [ selectedCurrencyUserBalance , setselectedCurrencyUserBalance] = useState();
    const [ userUkcBalance , setuserUkcBalance] = useState(0);
    const [ selectedCurrency , setSelectedCurrency] = useState();
    const [ tokenInput , settokenInput ] = useState();
    const [ ukcInput , setukcInput ] = useState();
    const [userId, setUserId] = useState("");
    const [connectedWalletAddr,setConnectedWalletAddr] = useState();
    const [userPackages,setUserPackages] = useState();
    const [packages,setPackages] = useState();
    const [contractObj,setContractObj] = useState();
    const [usdtContractObj,setUsdtContractObj] = useState();
    const [rbcContractObj,setRbcContractObj] = useState();
    const [tableData,setTableData] = useState([{sr_no:'Loading...',
                                                from_user:'Loading...',
                                                container:'Loading...',
                                                income_type : 'Loading...',
                                                amount :'Loading...', 
                                                data_and_time :'Loading...' 
                                              }])
    const columns = [
      {
        name: 'Sr. No.',
        selector: row => row.sr_no,
      },
      {
        name: 'From User',
        selector: row => row.from_user,
      },
      {
        name: 'Container',
        selector: row => row.container,
      },
      {
        name: 'Income Type',
        selector: row => row.income_type,
      },
      {
        name: 'Amount',
        selector: row => row.amount,
      },
      {
        name: 'Date & TIme',
        selector: row => row.data_and_time,
      },
    ];

   

  
    // 2. Set chains
    const mainnet ={
      chainId: 137,
      chainIdHex:'0x89',
      name: 'Polygon Smart Chain',
      currency: 'MATIC',
      explorerUrl: 'https://bscscan.com',
      rpcUrl: 'https://polygon-rpc.com'
      /*
      chainId: 97,
      name: 'Binance Smart Chain Testnet',
      currency: 'tBNB',
      explorerUrl: 'https://testnet.bscscan.com',
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'*/
    }
  
    const bscRpc = "https://polygon-rpc.com/";
    const validChainId = 137;
    // 3. Create modal
    const metadata = {
      name: 'uckccoin',
      description: 'uckccoin',
      url: 'https://test.ukccoin.io/', // origin must match your domain & subdomain
      icons: ['https://test.ukccoin.io/html/images/UKC_Logo.svg']
    }
  
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })
  
  
  const getSignerOrProvider = async (needSigner = false) => {
    let ethersProvider = new BrowserProvider(walletProvider);
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }
  
      
      const signer = await ethersProvider.getSigner();
  
      if (needSigner) {
        const signer = await ethersProvider.getSigner();
  
        return signer;
      }
  
      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  
  


  const getUserData = async (walletAddr,contractObjGet,rbcContractObjGet) => {
    try {
      if(walletAddr){
        var cuUserId = await contractObjGet.userId(walletAddr);
        cuUserId = parseInt(cuUserId);
        setUserId(cuUserId);

        const Parent=await contractObjGet.referal(walletAddr);
        const    TotalIncome= await contractObjGet.getDrainIncome(cuUserId);
        
        
        $('#total_drain_income').text(Number(parseInt(TotalIncome)/(10**6)).toFixed(4));
      

        let dataDefault=[];
      
        let getData=await contractObjGet.getDrainData(cuUserId);
        let IncomeTypeArr = ['Direct Income','Container Income','Drain Income','Drain Bonus'];
        if(getData.length>0)
        {
            
    
            let data=[];
             
            for(let i =getData.length-1;i>=0;i--)
            {
                let date= new Date(Number(getData[i][3])*1000);
            
    
                data.push({sr_no:getData.length-i,
                           from_user:Parent.substr(0,5)+"....."+Parent.substr(-5),
                           container:parseInt(getData[i][0]),
                           income_type : IncomeTypeArr[parseInt(getData[i][1])-1],
                           amount :Number(Number(parseInt(getData[i][2])/(10**6))).toFixed(4)+ " $", 
                           data_and_time :date.toLocaleString() 
                          })
            }
            setTableData(data);
        }
        else {
            setTableData([]); 
        }
       
       

      }
      

    } catch (err) {
      console.error(err);
    }
  };



const getUserDataWeb3 = async (walletAddr,contractObjGet,rbcContractObjGet) => {
  try {
    if(walletAddr){
      var cuUserId = await contractObjGet.methods.userId(walletAddr).call();
      cuUserId = parseInt(cuUserId);
      setUserId(cuUserId);

      const Parent=await contractObjGet.methods.referal(walletAddr).call();
      const    TotalIncome= await contractObjGet.methods.getDrainIncome(cuUserId).call();
      
      
      $('#total_drain_income').text(Number(parseInt(TotalIncome)/(10**6)).toFixed(4));
    

      let dataDefault=[];
    
      let getData=await contractObjGet.methods.getDrainData(cuUserId).call();
      let IncomeTypeArr = ['Direct Income','Container Income','Drain Income','Drain Bonus'];
      if(getData.length>0)
      {
          
  
          let data=[];
           
          for(let i =getData.length-1;i>=0;i--)
          {
              let date= new Date(Number(getData[i][3])*1000);
          
  
              data.push({sr_no:getData.length-i,
                         from_user:Parent.substr(0,5)+"....."+Parent.substr(-5),
                         container:parseInt(getData[i][0]),
                         income_type : IncomeTypeArr[parseInt(getData[i][1])-1],
                         amount :Number(Number(parseInt(getData[i][2])/(10**6))).toFixed(4)+ " $", 
                         data_and_time :date.toLocaleString() 
                        })
          }
          setTableData(data);
      }
      else {
          setTableData([]); 
      }
     
     

    }
    

  } catch (err) {
    console.error(err);
  }
};



  const accountsChanged =() =>{
    window.location.reload();
  }

  const chainChanged =() =>{
    window.location.reload();

  }

  const isRegistered = async()=>{
    //if(!contractObj){
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
       var contractObjData = new Contract(contract_address,contract_abi, provider);
       setContractObj(contractObjData);
      var usdtContractObjData = new Contract(usdt_address,usdt_abi, provider);
      setUsdtContractObj(usdtContractObjData);
      var rbcContractObjData = new Contract(RBC_token,RBC_abi, provider);
      setRbcContractObj(rbcContractObjData);

      

      setConnectedWalletAddr(signer.address);
      const checkIt = await contractObjData.isRegistered(signer.address);

      if(checkIt==true){
          getUserData(signer.address,contractObjData,rbcContractObjData);
        
      }
      else {
          window.location.href="/";
      }
    //}
  }


  const unRegistered = async()=>{
    var contractObjData = new web3.eth.Contract(contract_abi,contract_address);
    setContractObj(contractObjData);
   var usdtContractObjData = new web3.eth.Contract(usdt_abi,usdt_address);
   setUsdtContractObj(usdtContractObjData);
   var rbcContractObjData = new web3.eth.Contract(RBC_abi,RBC_token);
   setRbcContractObj(rbcContractObjData);

   var walletAddress = sessionStorage.getItem('preview_adr');

   setConnectedWalletAddr(walletAddress);
  
   const checkIt = await contractObjData.methods.isRegistered(walletAddress).call();
  
   if(checkIt==true){
    getUserDataWeb3(walletAddress,contractObjData,rbcContractObjData);
     
   }
   else {
       window.location.href="/";
   }
  }


  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

useEffect( () => {

  if (isConnected) {
   
    walletProvider.on('accountsChanged', accountsChanged); 
    walletProvider.on('chainChanged', chainChanged);
    isRegistered();

}
else {
  unRegistered();
}
    
    if(!isConnected){
      console.log("b");
        setSelectedCurrency("");
        setukcInput("");
        settokenInput("");
        setuserUkcBalance("");
        setselectedCurrencyUserBalance("");
        $("#currency_id").val("");
        $("#table_body").html();
       // window.location.href="/";
    }
},[isConnected]);


  return (
  <div>
    <div className="top_bar">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText">
            <span className="icon-bar"><i className="fa fa-bars"></i></span>
        </button>
        <div className="row align-items-center">
            <div className="col-4">
            <Link className="navbar-brand" href="/dashboard"><img src="rabito/img/logo.svg" alt="header-Logo" className="logo2" /></Link>
            </div>
            <div className="col-auto ml-auto">
            {(isConnected) ? <w3m-button balance="hide" label="Connect Wallet"/> : <Link className="btn btn-action btn_man_small  ml-auto" to='/'>Home</Link> }
            </div>
        </div>
    </div>
    <div className="dash_bar_man">
        <div className="left_bar">
            <Sidebar/>
        </div>
        <div className="right_bar">
        <h4 class="fw600 mb-3"> Drain Passive Income ( <span id="total_drain_income" style={{color:'#00d7eb'}}>0.0000</span> $)</h4>
            <div class="dash_box">

                <DataTable columns={columns} data={tableData}  pagination />

            </div>
        </div>
    </div>
  </div>

  );
}
